import React from "react";

function NotFoundPage() {
  return (
    <div className="fixed inset-0 backdrop-blur-[64px] !z-[2]">
      <div className="text-center flex flex-row justify-center items-center w-full h-full">
        <div>
          <h1 className="text-6xl font-bold text-[#2ED3B7]">404 - Sorry!</h1>
          <p className="text-lg text-white py-4">
            The page you're looking for cannot be found.
          </p>
          <a
            href="/"
            className="text-blue-500 font-medium hover:text-[#2ED3B7]"
          >
            Go to Homepage
          </a>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
