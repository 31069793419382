import { useCallback, useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import { useWallet } from "@manahippo/aptos-wallet-adapter";
import { useStore } from "store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ConnectWallet from "./ConnectWallet";
import Logout from "../assets/icons/logout.svg";
import Copy from "../assets/icons/Icon-copy.svg";
import { addressSlice, copyAddress, handleDappDetails } from "utils/helpers";
import Kanaloader from "../assets/kanaloader.json";
import Lottie from "react-lottie-player";
import Information from "../assets/icons/information.svg";

export function isValidAddressFormat(address: string): boolean {
  const addressPattern = /^0x[0-9a-fA-F]+$/;
  return addressPattern.test(address);
}
export function isValidEmailFormat(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

let loading: any;

const Register = () => {
  const navigate = useNavigate();
  const { connected, wallet, disconnect } = useWallet();
  const {
    iswalletConnect,
    updateWalletConnect,
    updateApiKey,
    address,
    dappDetails,
  } = useStore();
  const [name, setName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setName("");
  }, [address]);

  const registerToDapp = async () => {
    if (connected) {
      updateWalletConnect(false);
      if (dappDetails?.length > 0) {
        navigate("/");
      } else {
        try {
          loading = toast.loading("Loading...");
          const url = `${process.env.REACT_APP_BASE_URL}/registerDapp`;
          const data = {
            name,
            address: address,
          };

          const response = await axios.get(url, {
            params: data,
          });
          if (response.status === 201) {
            updateApiKey(response?.data?.api_key);
            toast.success("Successfully registered");
            setTimeout(() => {
              navigate("/");
              toast.dismiss(loading);
            }, 1000);
            setIsLoading(false);
          }
        } catch (error: any) {
          if (error.response.data.message === "Address already registered") {
            toast.error("Address already registered");
            navigate("/");
            setIsLoading(false);
          } else {
            toast.error("Failed to register");
            setIsLoading(false);
          }
        }
      }
    } else {
      toast.warning("Please connect to wallet");
    }
  };

  const handleConnectDisconnect = () => {
    if (connected) {
      disconnect();
    }
  };

  return (
    <div>
      {isLoading && (
        <div
          className={`fixed inset-0 !z-[2]  backdrop-blur-2xl  font-inter  items-center overflow-y-auto  h-[100%] w-[100%] flex flex-col justify-start  bg-[black]   `}
        >
          <div className="flex justify-center align-middle pt-60 !bg-transparent">
            <Lottie
              className="!bg-transparent"
              loop
              animationData={Kanaloader}
              play
              style={{ width: 150, height: 150 }}
            />
          </div>
          <div
            className={`!bg-transparent text-center text-[0.875rem]  align-middle 2xl:pt-28 xl:pt-28 lg:pt-28 md:pt-10 sm:pt-4 max-sm:pt-4 text-[#FFFFFF] `}
          >
            This may take few seconds, don’t close the window
          </div>
        </div>
      )}

      {!connected ? (
        <>{navigate("/")}</>
      ) : (
        <div className=" font-manrope !fixed z-[2] inset-[0] h-full w-full backdrop-blur-[0.75rem] flex flex-col justify-center items-center">
          <div className="flex flex-row justify-end items-end w-full mt-[-3rem]">
            <div className="w-[24.333rem] h-[3.5rem] bg-[#1D1E20] rounded-[1rem] flex justify-between p-3 mr-4">
              <div className=" flex">
                <img
                  className=" rounded-full w-7 h-7"
                  src={wallet?.adapter?.icon}
                  alt="token"
                />
                <div className="text-[0.875rem] gap-[0.5rem] font-[800] text-[#2ED3B7] pt-1 pl-3">
                  {addressSlice(address)}
                </div>
                <span
                  className="cursor-pointer"
                  onClick={() => copyAddress(address)}
                ></span>
              </div>
              <div className=" flex">
                <img
                  src={Copy}
                  alt="Copy"
                  className="cursor-pointer w-4 h-4"
                  onClick={() => copyAddress(address)}
                />
                <img
                  src={Logout}
                  alt="Logout"
                  className=" cursor-pointer w-4 h-4 ml-3"
                  onClick={handleConnectDisconnect}
                />
              </div>
            </div>
          </div>
          <div className="font-inter w-full h-[80vh] flex flex-row justify-center items-center align-middle">
            <div className=" w-full h-full flex  flex-col justify-center items-center align-middle">
              <div className="p-[24px] w-[500px] h-auto shadow-custom rounded-[16px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54px]">
                <div className="text-[16px] gap-[8px] font-[600] text-[rgba(255,255,255,0.80)] flex flex-row justify-start items-center">
                  Name
                </div>
                <input
                  className="text-[16px] gap-[8px] font-[600] text-[white] flex flex-row justify-start items-center w-full bg-[transparent] outline-none mt-[8px] mb-[16px] rounded-[16px] p-[16px] gap-[16px] border-[1px] border-[rgba(255,255,255,0.10)]"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => {
                    const enteredName = e.target.value;
                    const trimmedName = enteredName.replace(
                      /[^a-zA-Z0-9]/g,
                      ""
                    ); // Replace any character that is not alphanumeric
                    setName(trimmedName);
                  }}
                />
                <div className=" flex">
                  <img src={Information} alt="Information" />
                  <div className="text-[white] text-[0.7rem] pl-3">
                    Only alphanumeric characters allowed
                  </div>
                </div>

                <div className="flex justify-center my-[0.8rem] w-full ">
                  <button
                    className="buy-button w-full"
                    onClick={() => {
                      if (name.trim() === "") {
                        toast.warning("Name is required");
                      } else if (name.trim().length > 50) {
                        toast.warning("Name is too long");
                      } else {
                        registerToDapp();
                      }
                    }}
                  >
                    <div className="buy-button-inner">
                      <span className=" text-[#2ED3B7] font-[800]">
                        Register for Paymaster
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {iswalletConnect && <ConnectWallet />}
    </div>
  );
};

export default Register;
