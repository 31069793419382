import { walletClient } from "./userBalanceFetch";

let feePayerBalanceValueWithoutDecimal: any;

export const fetchBalanceFromFeePayer = async (address: string) => {
  try {
    let aptosBalance = await walletClient.balance(address);

    if (aptosBalance !== undefined) {
      for (const feePayerBalance of aptosBalance.balances) {
        if (
          feePayerBalance.coin ===
          "0x1::coin::CoinStore<0x1::aptos_coin::AptosCoin>"
        ) {
          const balanceValue = feePayerBalance.value / Math.pow(10, 8);
          feePayerBalanceValueWithoutDecimal = feePayerBalance.value;
          if (isNaN(balanceValue)) {
            return 0;
          } else {
            return balanceValue.toFixed(8);
          }
        }
      }
    } else {
      return 0;
    }
  } catch (error: any) {
    console.log(error);
  }
};
