import { getRpcUrl } from "./getRpcUrl";

export const WalletClient = require("aptos-wallet-api/src/wallet-client");
export let NODE_URL: any;
export const FAUCET_URL = "https://faucet.testnet.aptoslabs.com";
export let walletClient: any;

export let balanceValueWithoutDecimal: any;

export const fetchBalanceFromUser = async (address: any, network: any) => {
  try {
    // Set the NODE_URL based on the network
    if (network === "mainnet") {
      NODE_URL = getRpcUrl(network);
      walletClient = new WalletClient(NODE_URL, FAUCET_URL);
    } else if (network === "testnet") {
      NODE_URL = getRpcUrl(network);
      walletClient = new WalletClient(NODE_URL, FAUCET_URL);
    } else {
      throw new Error("Invalid network specified");
    }

    // Reinitialize wallet client with the updated NODE_URL
    walletClient = new WalletClient(NODE_URL, FAUCET_URL);

    let aptosBalance = await walletClient.balance(address);

    if (aptosBalance !== undefined) {
      for (const balance of aptosBalance.balances) {
        if (
          balance.coin === "0x1::coin::CoinStore<0x1::aptos_coin::AptosCoin>"
        ) {
          const balanceValue = balance.value / Math.pow(10, 8);
          balanceValueWithoutDecimal = balance.value;
          if (isNaN(balanceValue)) {
            return 0;
          } else {
            return balanceValue.toFixed(8);
          }
        }
      }
    } else {
      return 0;
    }
  } catch (error: any) {
    console.log(error);
  }
};
