import Pagination from "pagination/Pagination";
import React from "react";

const SkeletonLoader = ({ currentPage, totalPage }: { currentPage: number, totalPage: number }) => {
  return (
    <div>
      <div className="">
        <table className="2xl:w-[82.5rem] xl:w-[82.5rem] lg:w-[70.5rem]">
          <thead>
            <tr className="text-[#777879] text-[1rem] font-[400] gap-[1rem] opacity-0.5 bg-[#111213] h-[3.25rem] animate-pulse">
              <th className="w-[6.938rem] px-4">Version</th>
              <th className="w-[17.391rem]">Sender Address</th>
              <th className="w-[17.391rem]">Fee Payer</th>
              <th className="w-[17.391rem]">Function</th>
              <th className=" w-[17.391rem] pl-28">Gas Used</th>
            </tr>
          </thead>
        </table>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
          <div className="bg-[#777879] h-4 w-[6.938rem] mb-2 mx-2 rounded"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-18"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-14"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-24"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded ml-36"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
          <div className="bg-[#777879] h-4 w-[6.938rem] mb-2 mx-2 rounded"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-18"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-14"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-24"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded ml-36"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
          <div className="bg-[#777879] h-4 w-[6.938rem] mb-2 mx-2 rounded"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-18"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-14"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-24"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded ml-36"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
          <div className="bg-[#777879] h-4 w-[6.938rem] mb-2 mx-2 rounded"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-18"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-14"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-24"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded ml-36"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
          <div className="bg-[#777879] h-4 w-[6.938rem] mb-2 mx-2 rounded"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-18"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-14"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-24"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded ml-36"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
          <div className="bg-[#777879] h-4 w-[6.938rem] mb-2 mx-2 rounded"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-18"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-14"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-24"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded ml-36"></div>
        </div>
        <div className="animate-pulse flex  p-4 border-b-2 border-[#ffffff1a] h-[3.25rem]">
          <div className="bg-[#777879] h-4 w-[6.938rem] mb-2 mx-2 rounded"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-18"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-14"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded mx-24"></div>
          <div className="bg-[#777879] h-4 w-[17.391rem] mb-2 rounded ml-36"></div>
        </div>
        <div className=" mb-8">
          <div className=" flex justify-center xxl:pt-6 xl:pt-6 lg:pt-6 md:pt-6 sm:pt-3.5 xd:pt-3.5 text-[#697586] font-manrope font-[500] text-[0.875rem]">
            Showing result {currentPage} of {totalPage}
          </div>
          <Pagination
            currentPage={0}
            totalPages={0}
            onPageChange={function (page: number): void {
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
