import axios from "axios";
import { toast } from "react-toastify";

export const copyKey = (value: any) => {
  const el = document.createElement("textarea");
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  toast.success("Key copied to clipboard", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
};

export const copyAddress = (value: any) => {
  const el = document.createElement("textarea");
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  toast.success("Address is copied ", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
};

export const addressSlice = (value: string) => {
  const addressSliced = `${value.slice(0, 9)}...${value.slice(-9)}`;
  return addressSliced;
};

export const apiKeySlice = (value: string) => {
  const apiKeySliced = `${value.slice(-4)}`;
  return apiKeySliced;
};

export const fetchAccountModules = async (userAddress: any, apikey: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_MAINNET_URL}/accounts/${userAddress}/modules?limit=1000`
    );
    if (
      response.status === 200 &&
      response.data[0].abi.exposed_functions.length > 0
    ) {
      const isEntryFunctions = response.data;
      return isEntryFunctions;
    }
  } catch (error: any) {
    toast.error(error);
  }
};

export const enableFeepayerAccountState = async (
  userAddress: any,
  apikey: any
) => {
  const dataparams = {
    feepayer_address: userAddress,
    status: "true",
  };
  const modifyUserState = `${process.env.REACT_APP_BASE_URL}/modifyFeepayerState`;
  const response = await axios.get(modifyUserState, {
    params: dataparams,
    headers: {
      "Content-Type": "application/json",
      "api-key": apikey as string,
    },
  });
  return response;
};

export const disableFeepayerAccountState = async (
  userAddress: any,
  apikey: any
) => {
  const dataparams = {
    feepayer_address: userAddress,
    status: "false",
  };
  const modifyUserState = `${process.env.REACT_APP_BASE_URL}/modifyFeepayerState`;
  const response = await axios.get(modifyUserState, {
    params: dataparams,
    headers: {
      "Content-Type": "application/json",
      "api-key": apikey as string,
    },
  });
  return response;
};
export const modifyAllowAllScriptsStateToInActive = async (apikey: any) => {
  try {
    const dataParams = {
      allow_scripts: false,
    };
    const allowAllScripts = `${process.env.REACT_APP_BASE_URL}/allowScriptFunctions`;
    const response = await axios.get(allowAllScripts, {
      params: dataParams,
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });

    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

export const modifyAllowAllScriptsStateToActive = async (apikey: any) => {
  try {
    const dataParams = {
      allow_scripts: true,
    };
    const allowAllScripts = `${process.env.REACT_APP_BASE_URL}/allowScriptFunctions`;
    const response = await axios.get(allowAllScripts, {
      params: dataParams,
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });

    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

export const modifyAllowAllFunctionsStateToActive = async (apikey: any) => {
  try {
    const dataParams = {
      allow_all_functions: true,
    };
    const allowAllFunctions = `${process.env.REACT_APP_BASE_URL}/allowAllFunctions`;
    const response = await axios.get(allowAllFunctions, {
      params: dataParams,
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });
    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

export const modifyAllowAllFunctionsStateToInActive = async (apikey: any) => {
  try {
    const dataParams = {
      allow_all_functions: false,
    };
    const allowAllFunctions = `${process.env.REACT_APP_BASE_URL}/allowAllFunctions`;
    const response = await axios.get(allowAllFunctions, {
      params: dataParams,
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });
    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};
export const getWhiteListedFunctionsList = async (apikey: any) => {
  try {
    const addFunctionWhitelistEndpoint = `${process.env.REACT_APP_BASE_URL}/getWhitelistedFunctions`;
    const response = await axios.get(addFunctionWhitelistEndpoint, {
      headers: {
        "Content-Type": "application/json",
        "api-key": apikey as string,
      },
    });
    if (response.status === 200 && response.data.success === true) {
      return response.data.userfunctions;
    }
  } catch (error: any) {
    return error.response.data.message;
  }
};
export const addFunctions = async (
  apikey: any,
  moduleName: any,
  functionName: any,
  moduleAddress: any
) => {
  try {
    const addFunctionWhitelistEndpoint = `${process.env.REACT_APP_BASE_URL}/addWhitelistFunctions`;

    const functionsToAdd = [
      {
        module_name: moduleName,
        function_name: functionName,
        module_address: moduleAddress,
      },
    ];

    const response = await axios.post(
      addFunctionWhitelistEndpoint,
      {
        functions: functionsToAdd,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": apikey as string,
        },
      }
    );
    if (response.status === 201 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.error);
  }
};

export const modifyFunctions = async (
  apikey: any,
  moduleName: any,
  functionName: any,
  moduleAddress: any,
  status: any
) => {
  try {
    const modifyFunctionStateEndpoint = `${process.env.REACT_APP_BASE_URL}/modifyFunctionState`;
    const functionsToModify = {
      module_name: moduleName,
      function_name: functionName,
      module_address: moduleAddress,
      status: status,
    };
    const response = await axios.post(
      modifyFunctionStateEndpoint,
      functionsToModify,
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": apikey as string,
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

export const handleDappDetails = async (address: any, signature: any, publicKey: any) => {
  const url = `${process.env.REACT_APP_BASE_URL}/getDappDetails`;
  const data = {
    address: address,
    signature: signature,
    publicKey: publicKey,
  };
  const response = await axios.post(url, data);
  return response;
};

export const fetchUSDPriceOfAptos = async (aptosAmount: any) => {
  const response = await axios.get(
    "https://api.coingecko.com/api/v3/simple/price?ids=aptos&vs_currencies=usd"
  );
  const aptosPriceUSD = response.data.aptos.usd;
  const totalUSDPrice = aptosPriceUSD * aptosAmount;
  return totalUSDPrice;
};

export const sender = (sender: any) => {
  if (!sender) return "";
  const slicedSender = `${sender.slice(0, 8)}...${sender.slice(-8)}`;
  return slicedSender;
};

export const signature = (feePayerAddress: any) => {
  const truncatedFeePayerAddress = feePayerAddress?.slice(0, 8);
  const truncatedHash = feePayerAddress?.slice(-8);
  return `${truncatedFeePayerAddress}...${truncatedHash}`;
};

export const payloadFunction = (functionString: any) => {
  if (!functionString) return "";
  const parts = functionString.split("::");
  if (parts.length < 2) return functionString;
  return parts.slice(-2).join("::");
};

export const argumentDetails = (argument: any) => {
  if (!argument) return "";
  const slicedArgument = `${argument.slice(0, 8)}...${argument.slice(-8)}`;
  return slicedArgument;
};

export const removePrefix = (functionString: any) => {
  if (!functionString) return "";
  return functionString.replace("0x1::", "");
};
