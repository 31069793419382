import { AptosClient } from "aptos";
import { EntryFunctionPayload } from "aptos/src/generated";
import axios from "axios";
import dotenv from "dotenv";
import { getRpcUrl } from "./getRpcUrl";
dotenv.config();

export const submitAptosEntryFunctionPayload = async (
  entryFunctionPayload: any,
  signTransaction: any,
  maxGas: any,
  network: string // Add network argument
) => {
  try {
    let rpcUrl: any;
    if (network === "mainnet") {
      rpcUrl = process.env.REACT_APP_MAINNET_URL;
    } else if (network === "testnet") {
      rpcUrl = process.env.REACT_APP_TESTNET_URL;
    } else {
      throw new Error("Invalid network specified");
    }

    let data = await axios.get(rpcUrl).then((response) => {
      return response.data;
    });

    const hash = await signTransaction(
      entryFunctionPayload as EntryFunctionPayload,
      {
        max_gas_amount: maxGas,
        gas_unit_price: "100",
        expiration_timestamp_secs:
          Math.ceil(data.ledger_timestamp / 1000000) + 240,
      }
    );

    const hashData: any = await getAptosRpcEndPoint(
      network
    ).waitForTransactionWithResult(hash?.hash);

    if (!hashData.success) {
      throw new Error(`Error: ${hashData.vm_status}`);
    }

    return hashData?.hash;
  } catch (error: any) {
    throw new Error(error.message ? error.message : error.toString());
  }
};

export const getAptosRpcEndPoint = (network: string) => {
  if (network === "mainnet") {
    const MAINNET_NODE_URL: any = getRpcUrl(network);
    return new AptosClient(MAINNET_NODE_URL);
  } else if (network === "testnet") {
    const TESTNET_NODE_URL: any = getRpcUrl(network);
    return new AptosClient(TESTNET_NODE_URL);
  } else {
    throw new Error("Invalid network specified");
  }
};
