// import React from "react";
// import chevronleftDisbled from "../assets/icons/chevronleft.svg";
// import chevronRightEnabled from "../assets/icons/chevronright.svg";
// import chevronleftEnabled from "../assets/icons/chevron_left_enabled.svg";
// import chevronRightDisabled from "../assets/icons/chevron_right_disabled.svg";
// interface PaginationProps {
//   currentPage: number;
//   totalPages: number;
//   onPageChange: (page: number) => void;
// }
// const Pagination: React.FC<PaginationProps> = ({
//   currentPage,
//   totalPages,
//   onPageChange,
// }) => {
//   const MAX_VISIBLE_PAGES = 5;
//   const pageNumbers = Array.from(
//     { length: MAX_VISIBLE_PAGES },
//     (_, i) => i + 1
//   );

//   return (
//     <ul className="flex pt-6 space-x-1 justify-center items-center">
//       <img
//         src={currentPage - 1 <= 0 ? chevronleftDisbled : chevronleftEnabled}
//         alt="chevronright"
//         onClick={() =>
//           onPageChange(currentPage - 1 <= 0 ? currentPage : currentPage - 1)
//         }
//         className=" cursor-pointer"
//       />
//       {pageNumbers.map((page) => (
//         <li key={page}>
//           <button
//             className={`${
//               page === currentPage
//                 ? "bg-[#FFFFFF0F] text-white"
//                 : "bg-transparent text-gray-700"
//             } px-3 py-2 rounded-md`}
//             onClick={() => onPageChange(page)}
//           >
//             {page}
//           </button>
//         </li>
//       ))}
//       {currentPage > 4 && (
//         <button
//           className={`${
//             currentPage === currentPage
//               ? "bg-[#FFFFFF0F] text-white"
//               : "bg-transparent text-gray-700"
//           } px-3 py-2 rounded-md`}
//           onClick={() => onPageChange(currentPage)}
//         >
//           {currentPage}
//         </button>
//       )}
//       <img
//         src={
//           currentPage + 1 > totalPages
//             ? chevronRightDisabled
//             : chevronRightEnabled
//         }
//         alt="chevronright"
//         onClick={() =>
//           onPageChange(
//             currentPage + 1 > totalPages ? currentPage : currentPage + 1
//           )
//         }
//         className=" cursor-pointer"
//       />
//     </ul>
//   );
// };

// export default Pagination;

import React from "react";
import chevronleftDisbled from "../assets/icons/chevronleft.svg";
import chevronRightEnabled from "../assets/icons/chevronright.svg";
import chevronleftEnabled from "../assets/icons/chevron_left_enabled.svg";
import chevronRightDisabled from "../assets/icons/chevron_right_disabled.svg";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const MAX_VISIBLE_PAGES = 3;

  const renderPageNumbers = () => {
    const startPage = Math.max(
      1,
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2)
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages);

    const pageNumbers = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => i + startPage
    );

    return pageNumbers.map((page) => (
      <li key={page}>
        <button
          className={`${
            page === currentPage
              ? "bg-[#FFFFFF0F] text-white"
              : "bg-transparent text-white-700"
          } px-3 py-2 rounded-md`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      </li>
    ));
  };

  return (
    <ul className="flex pt-6 space-x-1 justify-center items-center">
      <img
        src={currentPage - 1 <= 0 ? chevronleftDisbled : chevronleftEnabled}
        alt="chevronright"
        onClick={() =>
          onPageChange(currentPage - 1 <= 0 ? currentPage : currentPage - 1)
        }
        className=" cursor-pointer"
      />
      {renderPageNumbers()}
      <img
        src={
          currentPage + 1 > totalPages
            ? chevronRightDisabled
            : chevronRightEnabled
        }
        alt="chevronright"
        onClick={() =>
          onPageChange(
            currentPage + 1 > totalPages ? currentPage : currentPage + 1
          )
        }
        className=" cursor-pointer"
      />
    </ul>
  );
};

export default Pagination;
