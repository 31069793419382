import { StateSlice, Store } from "store/types";

export type AppSlice = {
  address: any;
  registerAddress: any;
  apikey: string;
  hash: string;
  dappDetails: any;
  allowance: any;
  iswalletConnect: any;
  walletNetwork: any;
  balance: any;
  activeTab: any;
  whitelisted: any[];
  feePayerAddresses: any[];
  feePayerBalance: any;
  popUpIsOpen: any;
  publicKey: any;
  signature: any;
  allowAllFunctions: any;
  allowAllScripts:any
  updateAllowAllFunctions(payload:any): void;
  updateAllowAllScripts(payload:any): void;
  updateSignature(payload: any): void;
  updateWhitelisted(payload: any[]): void;
  updatePublicKey(payload: any[]): void;
  updateAddress(payload: any): void;
  updateRegisterAddress(payload: any): void;
  updateApiKey(payload: string): void;
  setHash(payload: any): void;
  updateDappDetails(payload: any): void;
  setAllowance(payload: any): void;
  updateWalletConnect(payload: any): void;
  setWalletNetwork(payload: any): void;
  updateBalance(payload: any): void;
  updateActiveTab(payload: any): void;
  updateFeePayerAddresses(payload: any): void;
  updateFeePayerBalance(payload: any): void;
  updatePopUpIsOpen(payload: any): void;
};

export const createAppSlice: StateSlice<Store, AppSlice> = (set) => ({
  address: "",
  updateAddress(payload: AppSlice["address"]) {
    set({ address: payload });
  },
  allowAllFunctions: "",
  updateAllowAllFunctions(payload: AppSlice["allowAllFunctions"]){
    set({allowAllFunctions: payload})
  },
  allowAllScripts: "",
  updateAllowAllScripts(payload: AppSlice["allowAllScripts"]){
    set({ allowAllScripts: payload });
  },
  signature: "",
  updateSignature(payload: AppSlice["signature"]) {
    set({ signature: payload });
  },
  publicKey: "",
  updatePublicKey(payload: AppSlice["publicKey"]) {
    set({ publicKey: payload });
  },
  registerAddress: "",
  updateRegisterAddress(payload: AppSlice["registerAddress"]) {
    set({ registerAddress: payload });
  },
  apikey: "",
  updateApiKey(payload: AppSlice["apikey"]) {
    set({ apikey: payload });
  },
  hash: "",
  setHash(payload: AppSlice["hash"]) {
    set({ hash: payload });
  },
  dappDetails: "",
  updateDappDetails(payload: AppSlice["dappDetails"]) {
    set({ dappDetails: payload });
  },
  popUpIsOpen: "",
  updatePopUpIsOpen(payload: AppSlice["popUpIsOpen"]) {
    set({ popUpIsOpen: payload });
  },
  whitelisted: [],
  updateWhitelisted(payload: AppSlice["whitelisted"]) {
    set({ whitelisted: payload });
  },
  allowance: "",
  setAllowance(payload: AppSlice["allowance"]) {
    set({ allowance: payload });
  },
  iswalletConnect: false,
  updateWalletConnect(payload: AppSlice["iswalletConnect"]) {
    set({ iswalletConnect: payload });
  },
  walletNetwork: "",
  setWalletNetwork(payload: AppSlice["walletNetwork"]) {
    set({ walletNetwork: payload });
  },
  balance: "",
  updateBalance(payload: AppSlice["balance"]) {
    set({ balance: payload });
  },
  feePayerBalance: [],
  updateFeePayerBalance(payload: AppSlice["feePayerBalance"]) {
    set({ feePayerBalance: payload });
  },
  feePayerAddresses: [],
  updateFeePayerAddresses(payload: AppSlice["feePayerAddresses"]) {
    set({ feePayerAddresses: payload });
  },
  activeTab: localStorage.getItem("activeTab"),
  updateActiveTab(payload: AppSlice["activeTab"]) {
    set({ activeTab: payload });
    localStorage.setItem("activeTab", payload.toString());
  },
});
