import React, { useCallback, useEffect, useState } from "react";
import { useWallet } from "@manahippo/aptos-wallet-adapter";
import { useStore } from "store";
import { toast } from "react-toastify";
import { submitAptosEntryFunctionPayload } from "utils/sign";
import { useNavigate } from "react-router-dom";
import chevron from "../assets/icons/chevron-down.svg";
import { balanceValueWithoutDecimal } from "utils/userBalanceFetch";
import Xclose from "../assets/icons/x-close.svg";
import ClickAwayListener from "react-click-away-listener";
import { fetchBalanceFromFeePayer } from "utils/feePayerBalanceFetch";
import { fetchUSDPriceOfAptos, handleDappDetails } from "utils/helpers";

interface DepositInterface {
  handleCloseDepositModel(): void;
}
const Deposit = (props: DepositInterface) => {
  const { handleCloseDepositModel } = props;
  const navigate = useNavigate();
  const { connected, signAndSubmitTransaction, disconnect, network } =
    useWallet();
  const {
    updateWalletConnect,
    updateDappDetails,
    updateApiKey,
    signature,
    updateWhitelisted,
    updateFeePayerAddresses,
    publicKey,
    updateFeePayerBalance,
    feePayerAddresses,
    updateSignature,
    balance,
    walletNetwork,
    apikey,
    address,
  } = useStore();
  const [amount, setAmount] = useState<string>("");
  const [selectedFeePayer, setSelectedFeePayer] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState<any[]>([]);
  const [totalFeePayerBalances, setTotalFeePayerBalances] = useState<any>();
  const [totalUSDPrice, setTotalUSDPrice] = useState<any>();
  const [depositType, setDepositType] = useState("");
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  let loading: any;
  const getDappDetails = useCallback(async () => {
    if (connected) {
      updateWalletConnect(false);
      setIsLoading(true);
      try {
        const response: any = await handleDappDetails(
          address,
          signature,
          publicKey
        );
        if (response.status === 200 && response.data.success) {
          const dappData = JSON.parse(response.data.dapp);
          const wrappedDappArray = [dappData];
          const whitelistData = wrappedDappArray[0].whitelist;

          if (response.data.dapp.length > 0) {
            setDetails(wrappedDappArray);
            updateDappDetails(response.data.dapp);
            updateApiKey(wrappedDappArray[0].api_key);
            updateWhitelisted(whitelistData);
            updateFeePayerAddresses(
              wrappedDappArray[0].feepayer_accounts.map(
                (account: any) => account.address
              )
            );
            setIsLoading(false);
          } else {
            navigate("/register");
          }
        }
      } catch (error: any) {
        if (
          error?.response?.data?.success === false &&
          error?.response?.data?.message === "Paymaster not available"
        ) {
          navigate("/register");
        } else {
          console.error(error);
        }
      }
    } else {
      toast.warning("Please connect to wallet");
    }
  }, [
    connected,
    updateDappDetails,
    address,
    publicKey,
    navigate,
    updateApiKey,
    updateFeePayerAddresses,
    updateWhitelisted,
    signature,
    updateWalletConnect,
  ]);

  useEffect(() => {
    if (connected) {
      setIsLoading(true);
      getDappDetails();
    } else {
      setDetails([]);
      updateApiKey("");
      updateSignature("");
    }
  }, [
    connected,
    updateDappDetails,
    disconnect,
    getDappDetails,
    updateApiKey,
    updateSignature,
    walletNetwork,
    address,
    publicKey,
    signature,
  ]);

  const toggleDropdown = () => {
    setIsOpenDropDown(!isOpenDropDown);
  };

  const deposit = async () => {
    const depositAmountValue = parseFloat(amount);

    if (!connected) {
      toast.warning("Please connect to the wallet");
      return;
    }

    if (!apikey) {
      toast.info("Please register");
      setIsLoading(false);
      return;
    }

    if (isNaN(depositAmountValue) || getDecimalPlaces(amount) > 8) {
      toast.warning("Invalid deposit amount");
      return;
    }

    const convertedAmount = depositAmountValue * 100000000;

    if (convertedAmount <= 0) {
      toast.warning("Invalid deposit amount");
      return;
    }

    if (network?.name?.toLowerCase() !== "mainnet") {
      toast.warning("Switch to the Mainnet");
      navigate("/");
      disconnect();
      return;
    }

    if (convertedAmount >= balanceValueWithoutDecimal) {
      toast.warning("Insufficient balance");
      setIsLoading(false);
      return;
    }

    try {
      loading = toast.loading("Loading...");
      const payload = {
        function:
          "0x8706612f5a40a594eaf837280282faaee4e101e70f05ac92cb2c43f4d83d072e::KanaGasStation::deposit",
        type_arguments: ["0x1::aptos_coin::AptosCoin"],
        arguments: [convertedAmount.toString()],
      };

      const hash = await submitAptosEntryFunctionPayload(
        payload,
        signAndSubmitTransaction,
        "40000",
        network?.name?.toLowerCase()
      );

      toast.dismiss(loading);
      const Hash = hash.toString();

      if (Hash.length > 0) {
        setIsLoading(true);
        const explorerLink = `https://explorer.aptoslabs.com/txn/${Hash}?network=mainnet`;
        toast.success(
          <div>
            Deposited successfully
            <button
              onClick={() => window.open(explorerLink, "_blank")}
              className="explorer-link-button"
            >
              Click to see in explorer
            </button>
          </div>
        );
        await getDappDetails();
        setIsLoading(false);
        navigate("/");
      }
    } catch (error) {
      toast.error("User rejected the deposit");
      toast.dismiss(loading);
      setIsLoading(false);
    }
  };

  // const depositEqualize = async () => { // used for later version
  //   const depositAmountValue = parseFloat(amount);
  //   if (connected) {
  //     if (apikey) {
  //       if (!isNaN(depositAmountValue)) {
  //         if (getDecimalPlaces(amount) <= 8) {
  //           const convertedAmount = depositAmountValue * 100000000;
  //           if (convertedAmount > 0) {
  //             if (walletNetwork === true) {
  //               if (convertedAmount < balanceValueWithoutDecimal) {
  //                 try {
  //                   loading = toast.loading("Loading...");
  //                   const payload = {
  //                     function:
  //                       "0x14f311c8f4bd73abd6751875a83a7ce394f1e1f22267695511e223b6d1f3727e::KanaGasRegistry::deposit_and_equalize",
  //                     type_arguments: ["0x1::aptos_coin::AptosCoin"],
  //                     arguments: [convertedAmount.toString()],
  //                   };

  //                   const hash = await submitAptosEntryFunctionPayload(
  //                     payload,
  //                     signAndSubmitTransaction,
  //                     "40000"
  //                   );

  //                   toast.dismiss(loading);
  //                   const Hash = hash.toString();
  //                   if (Hash.length > 0) {
  //                     setIsLoading(true);
  //                     const explorerLink = `https://explorer.aptoslabs.com/txn/${Hash}?network=mainnet`;
  //                     toast.success(
  //                       <div>
  //                         Deposited successfully
  //                         <button
  //                           onClick={() => window.open(explorerLink, "_blank")}
  //                           className="explorer-link-button"
  //                         >
  //                           Click to see in explorer
  //                         </button>
  //                       </div>
  //                     );
  //                     setIsLoading(false);
  //                     navigate("/");
  //                   }
  //                 } catch (error) {
  //                   toast.error("User rejected the deposit");
  //                   toast.dismiss(loading);
  //                   setIsLoading(false);
  //                 }
  //               } else {
  //                 toast.warning("Insufficient balance");
  //                 setIsLoading(false);
  //               }
  //             } else {
  //               toast.warning("Switch to the Mainnet");
  //               navigate("/");
  //               disconnect();
  //             }
  //           } else {
  //             toast.warning("Invalid deposit amount");
  //           }
  //         } else {
  //           toast.warning("Invalid deposit amount");
  //         }
  //       } else {
  //         toast.warning("Invalid deposit amount");
  //       }
  //     } else {
  //       toast.info("Please register");
  //       setIsLoading(false);
  //     }
  //   } else {
  //     toast.warning("Please connect to the wallet");
  //   }
  // };

  const depositSpecific = async (feePayeraddress: string) => {
    const depositAmountValue = parseFloat(amount);

    if (!connected) {
      toast.warning("Please connect to the wallet");
      return;
    }

    if (!apikey) {
      toast.info("Please register");
      setIsLoading(false);
      return;
    }

    if (isNaN(depositAmountValue) || getDecimalPlaces(amount) > 8) {
      toast.warning("Invalid deposit amount");
      return;
    }

    const convertedAmount = depositAmountValue * 100000000;

    if (convertedAmount <= 0) {
      toast.warning("Invalid deposit amount");
      return;
    }

    if (
      !(
        network?.name?.toLowerCase() === "mainnet" ||
        network?.name?.toLowerCase() === "testnet"
      )
    ) {
      toast.warning("Switch to the Mainnet or Testnet");
      navigate("/");
      disconnect();
      return;
    }

    if (convertedAmount >= balanceValueWithoutDecimal) {
      toast.warning("Insufficient balance");
      setIsLoading(false);
      return;
    }

    try {
      loading = toast.loading("Loading...");
      const payload = {
        function:
          network?.name?.toLowerCase() === "testnet"
            ? "0x1::aptos_account::transfer_coins"
            : "0x8706612f5a40a594eaf837280282faaee4e101e70f05ac92cb2c43f4d83d072e::KanaGasStation::deposit_to_specific",
        type_arguments: ["0x1::aptos_coin::AptosCoin"],
        arguments: [feePayeraddress, convertedAmount.toString()],
        ...(network?.name?.toLowerCase() === "testnet" && {
          type: "entry_function_payload",
        }),
      };

      const hash = await submitAptosEntryFunctionPayload(
        payload,
        signAndSubmitTransaction,
        "40000",
        network?.name?.toLowerCase()
      );

      toast.dismiss(loading);
      const Hash = hash.toString();

      if (Hash.length > 0) {
        setIsLoading(true);
        const explorerNetwork =
          network?.name?.toLowerCase() === "mainnet" ? "mainnet" : "testnet";
        const explorerLink = `https://explorer.aptoslabs.com/txn/${Hash}?network=${explorerNetwork}`;
        toast.success(
          <div>
            Deposited successfully
            <button
              onClick={() => window.open(explorerLink, "_blank")}
              className="explorer-link-button"
            >
              Click to see in explorer
            </button>
          </div>
        );
        await getDappDetails();
        setIsLoading(false);
        setSelectedFeePayer(feePayeraddress);
        navigate("/");
      }
    } catch (error) {
      toast.error("User rejected the deposit");
      toast.dismiss(loading);
      setIsLoading(false);
    }
  };

  const fetchFeePayerBalance = useCallback(async () => {
    if (feePayerAddresses) {
      const individualBalances = [] as any[];

      for (const address of feePayerAddresses) {
        try {
          const feepayerBalance: any = await fetchBalanceFromFeePayer(address);
          if (!isNaN(feepayerBalance)) {
            individualBalances.push({ address, balance: feepayerBalance });
            updateFeePayerBalance(individualBalances);
          }
        } catch (error) {
          toast.error(`Error while fetching balance for address ${address}`);
        }
      }

      const balancesOnly = individualBalances.map((item: any) =>
        parseFloat(item.balance)
      );
      const totalBalance = balancesOnly.reduce(
        (acc, balance) => acc + balance,
        0
      );
      setTotalFeePayerBalances(totalBalance.toFixed(8));
    }
  }, [feePayerAddresses, updateFeePayerBalance]);

  async function fetchAptosPriceInUSD(aptosAmount: any) {
    try {
      if (
        aptosAmount !== undefined &&
        aptosAmount !== null &&
        aptosAmount !== 0
      ) {
        const response = await fetchUSDPriceOfAptos(aptosAmount);
        setTotalUSDPrice(response.toFixed(2));
      } else {
        setTotalUSDPrice(0);
      }
    } catch (error) {
      console.error("Error fetching Aptos price:", error);
      throw error;
    }
  }

  useEffect(() => {
    if (connected) {
      fetchFeePayerBalance();
      fetchAptosPriceInUSD(balance);
    }
  }, [connected, fetchFeePayerBalance, network?.name, address, balance]);

  function getDecimalPlaces(value: any) {
    const match = (value + "").match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) return 0;
    return Math.max(
      0,
      (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
    );
  }

  const handleDepositClick = async () => {
    if (!depositType) {
      toast.warning("Please select a deposit type");
      return;
    }
    if (depositType === "DEPOSIT") {
      deposit();
    } else if (depositType === "DEPOSIT_SPECIFIC") {
      if (!selectedFeePayer) {
        toast.warning("Please select a fee-payer account");
      } else {
        await depositSpecific(selectedFeePayer);
      }
    }
  };

  const handleFeePayerClick = (selectedFeePayer: any) => {
    updateFeePayerAddresses(selectedFeePayer);
    toggleDropdown();
  };

  const handleClose = () => {
    setIsModalOpen(false);
    handleCloseDepositModel();
    toast.dismiss(loading);
  };
  const handleClickAway = () => {
    setIsModalOpen(false);
    navigate("/");
  };
  return (
    <div>
      {isModalOpen && (
        <div className=" font-manrope !fixed !z-[4] inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[0.75rem] flex flex-row justify-center items-center">
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="flex">
              <div className="w-[35rem] h-[25rem] rounded-[1rem] border-2 border-[#ffffff1a] bg-[#17181A]">
                <div className="flex p-4 border-b-2 border-[#ffffff1a]">
                  <div className="bg-[#0C0C0D] p-[1rem] rounded-[1rem] w-[2.5rem] h-[2.5rem] flex justify-center items-center text-[1rem] font-[800] text-[#FFFFFF]">
                    1.
                  </div>
                  <div className=" pl-3">
                    {network?.name?.toLowerCase() === "mainnet" ? (
                      <>
                        <div className="text-[1.125rem] text-[#FFFFFFCC] font-[800]">
                          Choose Deposit mode
                        </div>
                        <div className=" flex text-[0.875rem]">
                          <div className="text-[#A5A5A6] font-[700] w-[9.875rem]">
                            Deposit:
                          </div>
                          <div className="text-[#A5A5A6] font-[400] pl-2 ">
                            Distributes the fund to fee-payer account equally
                          </div>
                        </div>
                        <div className=" flex text-[0.875rem]">
                          <div className="text-[#A5A5A6] font-[700] w-[9.875rem]">
                            Deposit to specific:
                          </div>
                          <div className="text-[#A5A5A6] font-[400]">
                            Deposit to a particular fee-payer account
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-[1.125rem] text-[#FFFFFFCC] font-[800]">
                          Choose Deposit mode
                        </div>
                        <div className=" flex text-[0.875rem]">
                          <div className="text-[#A5A5A6] font-[700] w-[9.875rem]">
                            Deposit to specific:
                          </div>
                          <div className="text-[#A5A5A6] font-[400]">
                            Deposit to a particular fee-payer account
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex p-4 border-b-2 border-[#ffffff1a]">
                  <div className="bg-[#0C0C0D] p-[1rem] rounded-[1rem] w-[2.5rem] h-[2.5rem] flex justify-center items-center text-[1rem] font-[800] text-[#FFFFFF]">
                    2.
                  </div>
                  <div className="pl-3">
                    <div className="text-[#FFFFFFCC] font-[800] text-[1.125rem]">
                      Enter the amount to deposit
                    </div>
                    <div className="text-[#A5A5A6] text-[0.875rem] font-[400]">
                      For example, if you want to deposit 0.1 APT - enter 0.1
                    </div>
                    <div className="text-[#A5A5A6] text-[0.875rem] font-[400]">
                      While you have the flexibility to deposit any amount, we
                      highly recommend a deposit of 1 APT to the fee-payer
                    </div>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="bg-[#0C0C0D] p-[1rem] rounded-[1rem] w-[2.5rem] h-[2.5rem] flex justify-center items-center text-[1rem] font-[800] text-[#FFFFFF]">
                    3.
                  </div>
                  <div className=" pl-3">
                    <div className="text-[#FFFFFFCC] font-[800] text-[1.125rem]">
                      Click Deposit
                    </div>
                    <div className="text-[#A5A5A6] text-[0.875rem] font-[400]">
                      Once the deposit is confirmed, the amount will be
                      deposited from your wallet to corresponding fee-payer
                      accounts
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[35rem] rounded-[1rem] border-2 border-[#ffffff1a] bg-[#121415] ml-2">
                <div className="bg-[#ffffff0f] h-[4.5rem] rounded-t-[1rem] flex justify-between p-4 text-[#FFFFFF] text-[1.125rem]">
                  <div>Deposit</div>
                  <img
                    src={Xclose}
                    alt="Xclose"
                    className=" cursor-pointer"
                    onClick={handleClose}
                  />
                </div>
                <div>
                  <div className="text-[#FFFFFFCC] font-[400] font-inter text-[0.875rem] px-7 pt-3 pb-2">
                    Deposit mode
                  </div>
                  <div className="flex justify-center">
                    {network?.name?.toLowerCase() === "mainnet" && (
                      <button
                        className={`w-[32rem] h-[4rem] rounded-[1rem] border-2 font-[800] font-manrope flex justify-start items-center pl-4 text-[#FFFFFFCC] ${
                          depositType === "DEPOSIT"
                            ? "border-[#15B79E]"
                            : "border-[#ffffff1a]"
                        }`}
                        onClick={() => setDepositType("DEPOSIT")}
                      >
                        <input
                          type="radio"
                          id="deposit"
                          name="depositType"
                          value="DEPOSIT"
                          checked={depositType === "DEPOSIT"}
                          onChange={() => setDepositType("DEPOSIT")}
                          style={{
                            backgroundColor:
                              depositType === "DEPOSIT" ? "#15B79E" : "#15B79E",
                          }}
                        />
                        <span className="pl-3">Deposit</span>
                      </button>
                    )}
                  </div>
                  <div className="flex justify-center pt-2">
                    {/* <button className=" w-[32rem] h-[4rem] rounded-[1rem] border-2 border-[#ffffff1a] text-[#FFFFFFCC] font-[800] font-manrope flex justify-start items-center pl-4">
                    <input
                      type="radio"
                      id="depositEqualize"
                      name="depositType"
                      value="DEPOSIT_EQUALIZE"
                      checked={depositType === "DEPOSIT_EQUALIZE"}
                      onChange={() => setDepositType("DEPOSIT_EQUALIZE")}
                    />
                    <span className=" pl-3">Deposit and Equalise </span>
                  </button> */}
                  </div>
                  <div className="flex justify-center pt-2">
                    <button
                      className={`w-[32rem] h-[4rem] rounded-[1rem] border-2 font-[800] font-manrope flex justify-start items-center pl-4 text-[#FFFFFFCC] ${
                        depositType === "DEPOSIT_SPECIFIC"
                          ? "border-[#15B79E]"
                          : "border-[#ffffff1a]"
                      }`}
                      onClick={() => setDepositType("DEPOSIT_SPECIFIC")}
                    >
                      <input
                        type="radio"
                        id="depositSpecific"
                        name="depositType"
                        value="DEPOSIT_SPECIFIC"
                        checked={depositType === "DEPOSIT_SPECIFIC"}
                        onChange={() => setDepositType("DEPOSIT_SPECIFIC")}
                        style={{
                          backgroundColor:
                            depositType === "DEPOSIT_SPECIFIC"
                              ? "#15B79E"
                              : "#15B79E",
                        }}
                      />
                      <span className=" pl-3">Deposit to specific</span>
                    </button>
                  </div>
                  {depositType === "DEPOSIT_SPECIFIC" && (
                    <>
                      <div className=" flex justify-center">
                        <div
                          className="cursor-pointer text-[0.75rem] font-[700] gap-[0.5rem]  p-[1rem]  w-[32rem] h-[4rem] rounded-[1rem] border-2 border-[#ffffff1a] text-[#FFFFFFCC] flex justify-between   my-[20px]"
                          onClick={toggleDropdown}
                        >
                          <span className=" text-[0.75rem] py-1 font-[800] text-[#777879] font-manrope ">
                            {selectedFeePayer
                              ? selectedFeePayer
                              : "Select fee-payer account"}
                          </span>
                          <img
                            src={chevron}
                            alt="Arrow"
                            className={`w-6 h-6 ${
                              isOpenDropDown ? "" : "rotate-[180deg]"
                            } `}
                          />
                        </div>
                      </div>
                      {isOpenDropDown && (
                        <div className=" flex justify-center">
                          <div className=" bg-[#0C0C0D] border-2 border-[#2e2f31] rounded-[1rem] w-[32rem] h-[7.875rem] overflow-y-scroll  absolute gap-[1rem] p-[0.75rem_1rem] mt-[-0.55rem] text-[0.75rem] ">
                            {feePayerAddresses.map(
                              (address: string, index: number) => (
                                <div
                                  className={`flex py-2 cursor-pointer hover:rounded-[0.5rem] hover:bg-[#ffffff0f] text-[0.75rem] ${
                                    address === selectedFeePayer
                                      ? "bg-[#ffffff0f] text-[0.75rem]"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => {
                                    handleFeePayerClick(address);
                                    setSelectedFeePayer(address);
                                  }}
                                >
                                  <div className="text-[#FFFFFFCC] text-[0.75rem] font-[800] pl-2">
                                    {address}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="text-[#FFFFFFCC] font-[400] font-inter text-[0.875rem] px-7 py-2  ">
                    Amount to be deposited
                  </div>

                  <div className="flex justify-center">
                    <div className=" w-[32rem] h-[7rem] rounded-[1rem] border-2 border-[#ffffff1a] text-[#FFFFFFCC] font-[800] font-manrope">
                      <div className=" flex justify-between w-[30rem] border-b-2 border-[#ffffff1a] p-3 ml-3">
                        <div className="text-[#777879] text-[]">APT</div>
                        <input
                          type="text"
                          placeholder="0.00"
                          className="outline-0 border-0 h-full bg-transparent  text-right font-manrope w-[25rem] text-[#FFFFFFCC] text-[1.25rem] font-[800] outline-none"
                          value={amount}
                          onChange={(e) => {
                            const input = e.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            if (input === "") {
                              setAmount("");
                            } else if (input === ".") {
                              setAmount("0.");
                            } else {
                              setAmount(input);
                            }
                          }}
                        />
                      </div>
                      <div className=" flex flex-row justify-between text-[0.625rem] items-center py-[1rem] px-[1.5rem] ">
                        <div className="flex text-[#A5A5A6] font-[400]">
                          <div>Balance</div>
                          <div className="text-[#FFFFFFCC] font-[700] pl-1">
                            {`${balance !== undefined ? `${balance}` : `${0}`}`}
                          </div>
                          <div className="pl-1">APT</div>
                        </div>
                        <div className="flex text-[#A5A5A6] font-[400]">
                          <div className="font-[700]">{totalUSDPrice}</div>
                          <div className="pl-1">USD</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center my-[0.8rem]">
                    <button
                      className="buy-button w-[32rem]"
                      onClick={handleDepositClick}
                    >
                      <div className="buy-button-inner">
                        <span className=" text-[#2ED3B7] font-[800]">
                          Deposit
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      )}
    </div>
  );
};

export default Deposit;
