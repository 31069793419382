import {
  MartianWalletAdapter,
  useWallet,
  WalletProvider,
} from "@manahippo/aptos-wallet-adapter";
import React, { ReactNode, useMemo } from "react";

export const useAptosContext = useWallet;

export const AptosWalletProvider = ({ children }: { children: ReactNode }) => {
  const wallets = useMemo(
    () => [new MartianWalletAdapter()],

    []
  );
  return (
    <WalletProvider
      wallets={wallets}
      autoConnect={false}
      onError={(error: Error) => {
        console.log("wallet errors: ", error.name);
      }}
    >
      {children}
    </WalletProvider>
  );
};

export default AptosWalletProvider;
