import React, { useCallback, useEffect, useMemo } from "react";
import close from "../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { useStore } from "store";
import { useAptosContext } from "./Walletprovider";
import { WalletReadyState } from "@manahippo/aptos-wallet-adapter";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ClickAwayListener from "react-click-away-listener";

const ConnectWallet: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateSignature, setWalletNetwork, updateWalletConnect } = useStore();
  const {
    wallets: aptosWallets,
    connect: aptosWalletConnect,
    disconnect: aptosDisconnect,
    connected,
    network,
    signMessage,
    account,
  } = useAptosContext();
  const [detected, undetected] = useMemo(() => {
    const detected: any = [];
    const undetected: any = [];
    for (const wallet of aptosWallets) {
      if (
        wallet.readyState === WalletReadyState.Installed ||
        wallet.readyState === WalletReadyState.Loadable
      ) {
        detected.push(wallet);
      } else if (wallet.readyState === WalletReadyState.NotDetected) {
        undetected.push(wallet);
      }
    }

    return [detected, undetected];
  }, [aptosWallets]);

  const handleDisconnect = useCallback(() => {
    aptosDisconnect();
    updateSignature("");
  }, [aptosDisconnect, updateSignature]);

  useEffect(() => {
    if (connected) {
      const isMainnet = detected.some(
        (wallet: any) => wallet.adapter?._network?.toLowerCase() === "mainnet"
      );
      const isTestnet = detected.some(
        (wallet: any) => wallet?.adapter?._network?.toLowerCase() === "testnet"
      );

      setWalletNetwork(isMainnet || isTestnet);
      if (
        network?.name?.toLowerCase() === ("mainnet" as any) ||
        network?.name?.toLowerCase() === ("testnet" as any)
      ) {
        updateWalletConnect(false);

        // Example of signing a message
        const payload = {
          message: "Kana Paymaster Dashboard Login",
          nonce: "Kanalabs",
        };

        // Sign the message
        signMessage(payload)
          .then((signature: any) => {
            updateSignature(signature?.signature);
          })
          .catch((error: any) => {
            aptosDisconnect();
          });
      } else {
        toast.warning("Switch to Mainnet or Testnet");
        aptosDisconnect();
      }
    }
  }, [
    connected,
    detected,
    handleDisconnect,
    navigate,
    setWalletNetwork,
    updateWalletConnect,
    network?.name,
    aptosDisconnect,
    signMessage,
    updateSignature,
    account?.address
  ]);

  const handleClickAway = () => {
    updateWalletConnect(false);
  };

  const panelClasses = `w-full h-[11rem] bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] rounded-b-[1rem] overflow-y-scroll`;

  return (
    <div className="fixed z-[4] inset-0 h-auto w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[2rem] flex flex-row justify-center items-center">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={`2xl:w-[35rem] bxl:w-[35rem] xl:w-[35rem] sxl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[21rem] max-sm:w-[21rem] h-[15rem] bg-[rgba(23,24,26,0.50)] border-[rgba(255,255,255,0.10)] backdrop-blur-[54.36563491821289px] bg-transparent border-2 rounded-[1rem]`}
        >
          <div className="flex justify-between p-[3%_5%] bg-[rgba(255,255,255,0.06)] rounded-t-[1rem]">
            <div className={`text-white font-[500] text-[1.25rem]`}>
              {t("Connect Wallet")}
            </div>
            <img
              src={close}
              alt="close"
              className="cursor-pointer"
              onClick={() => {
                updateWalletConnect(false);
                updateSignature("");
              }}
            />
          </div>
          <div className="flex justify-center outline-none h-full">
            <div className={panelClasses}>
              <ul className="flex flex-col">
                {detected &&
                  detected.map((item: any, index: any) => {
                    return (
                      <div
                        className={`flex items-center p-4  rounded-[1rem] hover:bg-[#f5fffa0f] cursor-pointer`}
                        onClick={() => aptosWalletConnect(item.adapter.name)}
                        key={index}
                      >
                        <img
                          className="w-7 h-7"
                          src={item.adapter.icon}
                          alt="token"
                        />
                        <div className="font-inter font-bold leading-5 mx-6">
                          <div className="text-lg text-white">
                            {item.adapter.name}
                          </div>
                        </div>
                        {item?.adapter?.connected && (
                          <div
                            className="flex-1 text-right text-white"
                            onClick={() => handleDisconnect()}
                          ></div>
                        )}
                      </div>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ConnectWallet;
