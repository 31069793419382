import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useWallet } from "@manahippo/aptos-wallet-adapter";
import { toast } from "react-toastify";
import { useStore } from "store";
import ConnectWallet from "./ConnectWallet";
import { IndexerClient } from "aptos";
import { walletClient } from "utils/userBalanceFetch";
import Pagination from "../pagination/Pagination";
import copy from "../assets/icons/copy-04-icon.svg";
import SkeletonLoader from "./SkeletonLoader";
import { useNavigate } from "react-router-dom";
import { argumentDetails, copyAddress, payloadFunction, removePrefix, sender, signature } from "utils/helpers";

const FeePayerTransactionHistory = () => {
  const { connected, network } = useWallet();
  const { iswalletConnect, updateWalletConnect, feePayerAddresses, apikey } =
    useStore();
  const [address, setAddress] = useState<string>("");
  const [hash, setHash] = useState<string>("");
  const [txns, setTxns] = useState<boolean>(false);
  const [transactionDetails, setTransactionDetails] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(7);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const feePayerTxnsHistory = useCallback(
    async (page: number) => {
      if (connected) {
        setLoading(true);
        updateWalletConnect(false);
        if (apikey) {
          try {
            let indexerEndpoint = "";
            if (network && network?.name?.toLowerCase() === "testnet") {
              indexerEndpoint =
                "https://indexer-testnet.staging.gcp.aptosdev.com/v1/graphql";
            } else if (network && network?.name?.toLowerCase() === "mainnet") {
              indexerEndpoint =
                "https://indexer.mainnet.aptoslabs.com/v1/graphql";
            } else {
              throw new Error("Invalid network");
            }

            const client = new IndexerClient(indexerEndpoint);
            const limit = 100;
            const offset = 0;

            for (const address of feePayerAddresses) {
              const txnDetails = await client.getAccountTransactionsData(
                address,
                {
                  options: {
                    limit,
                    offset,
                  },
                }
              );

              const transactionDetails = await Promise.all(
                txnDetails.account_transactions.map(
                  async (transaction: any) => {
                    try {
                      const detail =
                        await walletClient.getTransactionDetailsByVersion([
                          transaction.transaction_version,
                        ]);
                      return detail?.transactionDetail;
                    } catch (error) {
                      console.error(
                        "Error fetching transaction detail:",
                        error
                      );
                      return null;
                    }
                  }
                )
              );

              const validTransactionDetails = transactionDetails.filter(
                (detail) => detail !== null && detail !== undefined
              );

              validTransactionDetails.sort(
                (a, b) => parseInt(b.timestamp) - parseInt(a.timestamp)
              );

              if (validTransactionDetails.length === 0) {
                setLoading(false);
              } else {
                const numberOfPages = Math.ceil(
                  validTransactionDetails.length / pageSize
                );
                setTransactionDetails((prevTransactionDetails) => {
                  if (page === currentPage) {
                    const existingHashes = prevTransactionDetails.map(
                      (tx) => tx.hash
                    );
                    const newTransactions = validTransactionDetails.filter(
                      (tx) => !existingHashes.includes(tx.hash)
                    );
                    return [...prevTransactionDetails, ...newTransactions];
                  } else {
                    const existingHashes = prevTransactionDetails.map(
                      (tx) => tx.hash
                    );
                    const newTransactions = validTransactionDetails.filter(
                      (tx) => !existingHashes.includes(tx.hash)
                    );

                    return [...prevTransactionDetails, ...newTransactions];
                  }
                });

                setAddress(validTransactionDetails[0].sender);
                setHash(validTransactionDetails[0].hash);
                setTxns(true);
                setTotalPage(numberOfPages);
                setLoading(false);
              }
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response);
          }
        } else {
          setLoading(false);
          toast.info("Please register");
        }
      } else {
        setLoading(false);
        toast.warning("Please connect to wallet");
      }
    },
    [
      connected,
      updateWalletConnect,
      apikey,
      network?.name,
      feePayerAddresses,
      pageSize,
      currentPage,
    ]
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    feePayerTxnsHistory(page);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return transactionDetails.slice(firstPageIndex, lastPageIndex);
  }, [pageSize, currentPage, transactionDetails]);

  useEffect(() => {
    if (connected) {
      feePayerTxnsHistory(currentPage);
    }
  }, [
    currentPage,
    network?.name,
    pageSize,
    feePayerAddresses,
    connected,
    feePayerTxnsHistory,
    updateWalletConnect,
  ]);

  useEffect(() => {
    setTransactionDetails([]);
  }, [network]);

  useEffect(() => {
    if (connected && network && currentPage !== 0) {
      feePayerTxnsHistory(currentPage);
    }
  }, [connected, currentPage, network, feePayerTxnsHistory]);


  useEffect(() => {
    let totalPages = Math.ceil(transactionDetails?.length / pageSize);
    setTotalPage(totalPages);
  }, [pageSize, transactionDetails]);

  return (
    <div>
      {!connected ? (
        <>{navigate("/")}</>
      ) : (
        <div className=" flex justify-center my-36 font-manrope">
          <div className="2xl:w-[82.5rem] xl:w-[82.5rem] sxl:w-[82.5rem] lg:w-[70.5rem] md:w-full sm:w-full xd:w-full  rounded-[1rem] border-2 border-[#ffffff1a] bg-[#17181A]">
            <div className=" border-b-2 border-[#ffffff1a] h-[3.5rem] text-[0.875rem] text-[#A5A5A6] font-[800] p-3">
              <div className=" flex justify-between">
                <div>Transaction History</div>
              </div>
            </div>
            <div>
              {loading ? (
                <SkeletonLoader
                  currentPage={currentPage}
                  totalPage={totalPage}
                />
              ) : txns &&
                transactionDetails &&
                transactionDetails?.length > 0 ? (
                <div className="text-[11px] font-[700] flex flex-col justify-center items-center overflow-auto no-scrollbar">
                  {currentTableData && currentTableData.length > 0 ? (
                    <div className="">
                      <table className="2xl:w-[82.5rem] xl:w-[82.5rem] lg:w-[70.5rem]">
                        <thead>
                          <tr className="text-[#777879] text-[1rem] font-[400] gap-[1rem] opacity-0.5 bg-[#111213] h-[3.25rem] w-full ">
                            <th className="w-[6.938remrem] px-4">Version</th>
                            <th className="w-[17.391rem]">Sender Address</th>
                            <th className="w-[17.391rem]">Fee Payer</th>
                            <th className="w-[17.391rem]">Function</th>
                            <th className=" w-[17.391rem] pl-28">Gas Used</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData.map((detail: any) => (
                            <tr className=" border-b-2 border-[#f5f7fa0f] bg-[#17181A]  rounded-[0.5rem] h-12 w-[3.875rem] text-[0.875rem]">
                              <td className="w-[6.938rem] px-4">
                                <a
                                  href={`https://explorer.aptoslabs.com/txn/${
                                    detail?.version
                                  }?network=${
                                    network?.name?.toLowerCase() === "mainnet"
                                      ? "mainnet"
                                      : "testnet"
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-[#2ED3B7]"
                                  title={detail?.version}
                                >
                                  {detail?.version}
                                </a>
                              </td>
                              {network?.name?.toLowerCase() === "mainnet" && (
                                <>
                                  <td className="text-[#FFFFFF] w-[17.391rem] font-[400]">
                                    <div className=" flex">
                                      {detail?.sender &&
                                      detail?.sender?.length > 20
                                        ? sender(detail?.sender)
                                        : detail?.sender}
                                      <img
                                        src={copy}
                                        alt="/"
                                        className=" ml-2 cursor-pointer"
                                        onClick={() => copyAddress(address)}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-[#FFFFFF] w-[17.391rem] font-[400]">
                                    {detail.signature &&
                                    detail?.signature?.fee_payer_address &&
                                    detail?.signature?.fee_payer_address
                                      ?.length > 20
                                      ? signature(
                                          detail?.signature?.fee_payer_address
                                        )
                                      : detail?.signature &&
                                        detail?.signature?.fee_payer_address}
                                  </td>
                                  <td className="text-[#FFFFFF] w-[17.391rem] font-[400]">
                                    {detail?.payload &&
                                    detail?.payload?.function
                                      ? detail?.payload?.function?.includes(
                                          "::"
                                        )
                                        ? payloadFunction(
                                            detail?.payload?.function
                                          )
                                        : detail?.payload?.function
                                      : ""}
                                  </td>
                                  <td className="text-[#FFFFFF] w-[17.391rem] font-[400] pl-28 ">
                                    {detail?.gas_used &&
                                      detail?.gas_used / 1000000}
                                  </td>
                                </>
                              )}
                              {network?.name?.toLowerCase() === "testnet" && (
                                <>
                                  <td className="text-[#FFFFFF] w-[17.391rem] font-[400]">
                                    <div className=" flex">
                                      {detail?.sender &&
                                      detail?.sender?.length > 20
                                        ? `${detail?.sender?.slice(
                                            0,
                                            8
                                          )}...${detail?.sender?.slice(-8)}`
                                        : detail?.sender}
                                      <img
                                        src={copy}
                                        alt="/"
                                        className=" ml-2 cursor-pointer"
                                        onClick={() => copyAddress(address)}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-[#FFFFFF] w-[17.391rem] font-[400]">
                                    {detail?.payload &&
                                    detail?.payload?.arguments &&
                                    detail?.payload?.arguments?.length === 2
                                      ? argumentDetails(
                                          detail?.payload?.arguments[0]
                                        )
                                      : detail?.payload &&
                                        detail?.payload?.arguments}
                                  </td>
                                  <td className="text-[#FFFFFF] w-[17.391rem] font-[400]">
                                    {detail?.payload &&
                                    detail?.payload?.function &&
                                    detail?.payload?.function?.length > 21
                                      ? removePrefix(detail?.payload?.function)
                                      : detail?.payload &&
                                        detail?.payload?.function}
                                  </td>
                                  <td className="text-[#FFFFFF] w-[17.391rem] font-[400] pl-28 ">
                                    {detail?.gas_used &&
                                      detail?.gas_used / 1000000}
                                  </td>
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="pt-10 text-[#FFFFFF]">
                      No transaction details available for this address.
                    </div>
                  )}
                  <div className=" mb-8">
                    <div className=" flex justify-center xxl:pt-6 xl:pt-6 lg:pt-6 md:pt-6 sm:pt-3.5 xd:pt-3.5 text-[#697586] font-manrope font-[500] text-[0.875rem]">
                      Showing result {currentPage} of {totalPage}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPage}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              ) : (
                <div className="font-inter bg-[#0C0C0D] w-full h-[80vh] flex flex-row justify-center items-center align-middle text-white">
                  No Transactions
                </div>
              )}
              {iswalletConnect && <ConnectWallet />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeePayerTransactionHistory;
