import Sidebar from "components/Sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "components/Register";
import Deposit from "components/Deposit";
import UsersWhitelist from "components/UserWhitelist";
import FeePayerTransactionHistory from "components/FeePayerTransactionHistory";
import Header from "components/Header";
import Dashboard from "components/DashBoard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFoundPage from "components/NotFoundPage";
import FunctionsWhitelist from "components/FunctionsWhitelist";

function App() {
  const handleCloseDepositModel = () => {};
  return (
    <>
      <BrowserRouter>
        <div
          className={` !overflow-x-hidden  bg-[#0C0C0D]  min-h-screen h-full font-inter flex flex-row justify-start items-start w-screen xxl:!gap-[1rem] bxl:!gap-[1rem] xl:!gap-[1rem] sxl:!gap-[1rem] `}
        >
          <div className=" xxl:inline bxl:inline xl:inline sxl:inline lg:hidden md:hidden sm:hidden xd:hidden w-[5%]  ">
            <Sidebar />
          </div>
          <div className="w-full flex flex-row justify-center items-center ">
            <div className="flex flex-col  xxl:!w-[1600px] bxl:!w-[1600px] xl:!w-[90%] sxl:!w-[95.5%] lg:!w-[96%] md:!w-[100%] sm:!w-[100%] xd:!w-[100%]    ">
              <Header />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/deposit"
                  element={
                    <Deposit
                      handleCloseDepositModel={handleCloseDepositModel}
                    />
                  }
                />
                <Route path="/users-whitelist" element={<UsersWhitelist />} />
                <Route
                  path="/transactions-history"
                  element={<FeePayerTransactionHistory />}
                />
                <Route
                  path="/functions-whitelist"
                  element={<FunctionsWhitelist />}
                />
                <Route path="/register" element={<Register />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
              <ToastContainer position="top-right" autoClose={3000} />
            </div>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
