import { useWallet } from "@manahippo/aptos-wallet-adapter";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useStore } from "store";
import ConnectWallet from "./ConnectWallet";
import Kana from "../assets/icons/kana_logo.svg";
import Lottie from "react-lottie-player";
import Kanaloader from "../assets/kanaloader.json";
import Copy from "../assets/icons/Icon-copy.svg";
import Eye from "../assets/icons/eye-icon.svg";
import { Switch } from "antd";
import { fetchBalanceFromFeePayer } from "utils/feePayerBalanceFetch";
import CopyIcon from "../assets/icons/Icon.png";
import {
  addressSlice,
  apiKeySlice,
  copyKey,
  disableFeepayerAccountState,
  enableFeepayerAccountState,
  handleDappDetails,
} from "utils/helpers";
const Dashboard = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState<any[]>([]);
  const [showKey, setShowKey] = useState<boolean>();
  const {
    iswalletConnect,
    updateWalletConnect,
    updateSignature,
    walletNetwork,
    updateFeePayerBalance,
    signature,
    feePayerBalance,
    feePayerAddresses,
    updateFeePayerAddresses,
    publicKey,
    address,
    updateApiKey,
    apikey,
    updateDappDetails,
    updateWhitelisted,
    updateAllowAllScripts,
    updateAllowAllFunctions,
  } = useStore();
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);
  const { connected, network, disconnect } = useWallet();
  const [isLoading, setIsLoading] = useState(true);
  const [totalFeePayerBalances, setTotalFeePayerBalances] = useState<any>();

  useEffect(() => {
    setDetails([]);
    setShowKey(false);
    updateSignature("");
    updateApiKey("");
    updateFeePayerAddresses([]);
    updateFeePayerBalance([]);
    setTotalFeePayerBalances(undefined);
  }, [address]);

  const getDappDetails = useCallback(async () => {
    if (!connected || !signature?.length) return;
    updateWalletConnect(false);
    setIsLoading(true);
    try {
      const response = await handleDappDetails(address, signature, publicKey);
      if (response.status === 200 && response.data.success) {
        const dappData = JSON.parse(response.data.dapp);
        const wrappedDappArray = [dappData];
        const whitelistData = wrappedDappArray[0].whitelist;

        setDetails(wrappedDappArray);
        updateDappDetails(response.data.dapp);
        updateApiKey(wrappedDappArray[0].api_key);
        updateAllowAllFunctions(wrappedDappArray[0].allow_all_functions);
        updateAllowAllScripts(wrappedDappArray[0].allow_scripts);
        updateWhitelisted(whitelistData);
        updateFeePayerAddresses(
          wrappedDappArray[0].feepayer_accounts.map(
            (account: any) => account.address
          )
        );
      } else {
        navigate("/register");
        updateDappDetails("")
      }
    } catch (error: any) {
      if (
        error?.response?.data?.success === false &&
        error?.response?.data?.message === "Paymaster not available"
      ) {
        navigate("/register");
        updateDappDetails("");
      }
    }
  }, [
    connected,
    updateDappDetails,
    address,
    publicKey,
    navigate,
    updateApiKey,
    updateFeePayerAddresses,
    updateWhitelisted,
    signature,
    updateWalletConnect,
    updateAllowAllFunctions,
    updateAllowAllScripts,
  ]);

  useEffect(() => {
    if (details?.length > 0 && totalFeePayerBalances !== undefined) {
      setIsLoading(false);
    }
  }, [details, totalFeePayerBalances]);

  useEffect(() => {
    if (connected) {
      setIsLoading(true);
      getDappDetails();
    } else {
      setDetails([]);
      updateApiKey("");
      updateSignature("");
    }
  }, [
    connected,
    updateDappDetails,
    disconnect,
    getDappDetails,
    updateApiKey,
    updateSignature,
    walletNetwork,
    address,
    publicKey,
    signature,
  ]);

  useEffect(() => {
    if ([details] && [details].length > 0 && isAlreadyRegistered) {
      setIsAlreadyRegistered(true);
    }
  }, [details, isAlreadyRegistered]);

  const connectToInstalledWallet = async () => {
    if (connected) {
      disconnect();
      updateSignature("");
    } else {
      updateWalletConnect(true);
    }
    updateWalletConnect(true);
  };

  const disableFeepayerAccount = async (userAddress: string) => {
    if (connected) {
      if (apikey) {
        try {
          setIsLoading(true);
          const response = await disableFeepayerAccountState(
            userAddress,
            apikey
          );

          if (response.status === 200) {
            await getDappDetails();
            setIsLoading(false);
            toast.success("Successfully Disabled FeepayerAccount");
          }
        } catch (error: any) {
          setIsLoading(false);
          toast.error(error.response.data.error);
        }
      } else {
        toast.info("Please register");
      }
    } else {
      toast.warning("Please connect to wallet");
    }
  };

  const enableFeepayerAccount = async (userAddress: string) => {
    if (connected) {
      if (apikey) {
        try {
          setIsLoading(true);
          const response = await enableFeepayerAccountState(
            userAddress,
            apikey
          );
          if (response.status === 200) {
            await getDappDetails();
            setIsLoading(false);
            toast.success("Successfully Enabled FeepayerAccount");
          }
        } catch (error: any) {
          toast.error(error.response.data.error);
        }
      } else {
        toast.info("Please register");
      }
    } else {
      toast.warning("Please connect to wallet");
    }
  };

  // const addFeepayers = async () => {  // need to be used for the new version release
  //   if (connected) {
  //     if (apikey) {
  //       try {
  //         setIsLoading(true);
  //         const addFeepayer = `${process.env.REACT_APP_BASE_URL}/addNewFeepayer`;
  //         const response = await axios.get(addFeepayer, {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "api-key": apikey as string,
  //           },
  //         });
  //         console.log("response: ", response);

  //         if (response.status === 200) {
  //           await getDappDetails();
  //           setIsLoading(false);
  //           toast.success("Successfully added Feepayer");
  //         }
  //       } catch (error: any) {
  //         setIsLoading(false);
  //         toast.error(error.response.data.error);
  //       }
  //     } else {
  //       toast.info("Please register");
  //     }
  //   } else {
  //     toast.warning("Please connect to wallet");
  //   }
  // };

  const fetchFeePayerBalance = useCallback(async () => {
    if (feePayerAddresses) {
      const individualBalances = [] as any[];

      for (const address of feePayerAddresses) {
        try {
          const feepayerBalance: any = await fetchBalanceFromFeePayer(address);
          if (!isNaN(feepayerBalance)) {
            individualBalances.push({ address, balance: feepayerBalance });
            updateFeePayerBalance(individualBalances);
            setIsLoading(false);
          }
        } catch (error) {
          toast.error(`Error while fetching balance for address ${address}`);
        }
      }

      const balancesOnly = individualBalances.map((item: any) =>
        parseFloat(item.balance)
      );
      const totalBalance = balancesOnly.reduce(
        (acc, balance) => acc + balance,
        0
      );
      setTotalFeePayerBalances(totalBalance.toFixed(8));
    }
  }, [feePayerAddresses, updateFeePayerBalance]);

  useEffect(() => {
    if (connected) {
      fetchFeePayerBalance();
    }
  }, [connected, fetchFeePayerBalance, network?.name, address]);

  const navigateToWhitelists = () => {
    navigate("/add ");
  };

  return (
    <div>
      {isLoading && (
        <div
          className={`fixed inset-0 !z-[2]  backdrop-blur-2xl  font-inter  items-center overflow-y-auto  h-[100%] w-[100%] flex flex-col justify-start  bg-[black]   `}
        >
          <div className="flex justify-center align-middle pt-60 !bg-transparent">
            <Lottie
              className="!bg-transparent"
              loop
              animationData={Kanaloader}
              play
              style={{ width: 150, height: 150 }}
            />
          </div>
          <div
            className={`!bg-transparent text-center text-[0.875rem]  align-middle 2xl:pt-28 xl:pt-28 lg:pt-28 md:pt-10 sm:pt-4 max-sm:pt-4 text-[#FFFFFF] `}
          >
            This may take few seconds, don’t close the window
          </div>
        </div>
      )}

      {!connected ? (
        <div className="fixed inset-0 backdrop-blur-[64px] !z-[2] bg-no-repeat bg-cover flex flex-col justify-between items-center bg-paymasterbg bg-full ">
          <div className="p-[24px] flex flex-row justify-between items-center w-full">
            <img src={Kana} alt="Kana" />
            <div className="flex flex-row items-center gap-[1rem] justify-center   w-auto !h-auto  ">
              <button
                className="buy-button w-auto "
                onClick={() => connectToInstalledWallet()}
              >
                <div className="buy-button-inner !h-[56px]  text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
                  Connect Wallet
                </div>
              </button>
              {/* <img src={Menu} alt="" /> */}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center    ">
            <div className="flex text-[white] flex-col justify-center items-center text-center   font-urbanist text-[84px] font-[800]  p-[24px]">
              <div>KANALABS PAYMASTER</div>
              <div className=" font-manrope text-[18px] font-[400] pt-[1rem]  pb-[2rem] ">
                Add, Manage Fee-Payer Accounts and Whitelists.
              </div>

              <div className="flex justify-center   w-auto !h-auto  ">
                <button
                  className="buy-button w-auto "
                  onClick={() => connectToInstalledWallet()}
                >
                  <div className="buy-button-inner !h-[56px]  text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
                    Connect Wallet
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center items-center pb-[1rem] ">
            <div className="bg-[#17181A] gap-[1rem] flex flex-row justify-center items-center border-[rgba(255,255,255,0.10)] p-[1rem] rounded-[1rem] ">
              <div className="text-[1rem]  font-source_code_pro font-[400] text-[#D2D2D2] p-[1rem] border-[1px] rounded-[1rem] bg-[#111213] border-[rgba(255,255,255,0.10)]">
                npm install @kanalabs/paymaster-sdk
                <br />
                or
                <br />
                yarn add @kanalabs/paymaster-sdk
              </div>
              <div className="text-[16px] flex flex-col gap-[0.5rem] justify-start items-start font-manrope font-[400] text-[rgba(255,255,255,0.89)]">
                Start implementing Paymaster now
                <div
                  className="text-[#2ED3B7] cursor-pointer font-manrope font-[800] text-[0.75rem] border-[1px] border-[#2ED3B7] rounded-[0.5rem] p-[0.5rem_1rem]"
                  onClick={() => {
                    window.open(
                      "https://docs.kanalabs.io/paymaster-service/kana-paymaster-for-aptos",
                      "_blank"
                    );
                  }}
                >
                  Read Documentation
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" mt-28 flex font-manrope flex flex-row justify-start items-start gap-[1rem]">
          {details && Array.isArray(details) && details?.length > 0 && (
            <div className="flex flex-col gap-[1rem]  w-[35%] ">
              {details.map((detail, index) => (
                <div
                  key={index}
                  className="w-full h-auto rounded-[1rem] bg-[#17181A] border-2 border-[#ffffff1a]  "
                >
                  <div className="text-[#A5A5A6] text-[0.875rem] h-[3.5rem] border-b-2 border-[#ffffff1a] p-3 font-[800]">
                    Account Details
                  </div>
                  <div className="p-[1rem] flex flex-col gap-[1rem] w-full">
                    <div className="w-full h-[4rem] rounded-[1rem] border-2 border-[#ffffff1a] bg-[#111213]    flex items-center flex-row justify-between p-3">
                      <div className="text-[#FFFFFFCC] text-[1rem] font-[800]">
                        Name
                      </div>
                      <div className="text-[#FFFFFFCC] text-[1rem] font-[400]">
                        {detail?.name}
                      </div>
                    </div>
                    <div className="w-full h-[4rem] rounded-[1rem] border-2 border-[#ffffff1a] bg-[#111213]    flex items-center flex-row justify-between p-3">
                      <div className="text-[#FFFFFFCC] text-[1rem] font-[800]">
                        Address
                      </div>
                      <div className="text-[#FFFFFFCC] text-[1rem] font-[400]">
                        {addressSlice(detail?.address)}
                      </div>
                    </div>
                    <div className="w-full h-[4rem] rounded-[1rem] border-2 border-[#ffffff1a] bg-[#111213]   flex items-center flex-row justify-between p-3">
                      <div className="text-[#FFFFFFCC] text-[1rem] font-[800]">
                        Status
                      </div>
                      <div className="text-[#12B76A] text-[1rem] font-[400]">
                        {detail?.is_active ? "Active" : "Inactive"}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {details.map((detail, index) => (
                <div
                  key={index}
                  className="w-full h-[9.5rem] rounded-[1rem] bg-[#17181A] border-2 border-[#ffffff1a]  "
                >
                  <div className="text-[#A5A5A6] text-[0.875rem] h-[3.5rem] border-b-2 border-[#ffffff1a] p-3 font-[800]">
                    Secret Key
                  </div>
                  <div className="w-full p-[1rem]">
                    <div className="w-full h-[4rem] rounded-[1rem] border-2 border-[#ffffff1a] bg-[#111213]   flex items-center flex-row justify-between p-3">
                      <div className="text-[#FFFFFFCC] text-[1rem] font-[400]">
                        {showKey ? (
                          detail?.api_key
                        ) : (
                          <span>
                            {"*".repeat(detail?.api_key?.length - 4)}
                            {apiKeySlice(detail?.api_key)}
                          </span>
                        )}
                      </div>
                      <div className=" flex flex-row justify-center items-center gap-[0.5rem]">
                        <img
                          src={Copy}
                          alt="Copy"
                          className=" cursor-pointer w-4 h-4"
                          onClick={() => copyKey(detail?.api_key)}
                        />
                        <img
                          src={Eye}
                          alt="Eye"
                          className="cursor-pointer w-4 h-4  "
                          onMouseDown={() => setShowKey(true)}
                          onMouseUp={() => setShowKey(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {details && Array.isArray(details) && details?.length > 0 && (
            <div className=" w-[65%]">
              <div className="rounded-[1rem] bg-[#17181A] border-2 border-[#ffffff1a]  ">
                <div className="text-[#A5A5A6] text-[0.875rem] h-[3.5rem] border-b-2 border-[#ffffff1a] p-3 font-[800] flex justify-between">
                  <div> Fee-Payer Accounts</div>
                  <div
                    className="text-[#2ED3B7] text-[0.75rem] cursor-pointer"
                    onClick={navigateToWhitelists}
                  >
                    Go to Whitelists
                  </div>
                </div>
                <div className="bg-[#111213] h-[4.625rem] flex justify-between p-3">
                  <div className="flex flex-col">
                    <div className="text-[#FFFFFFCC] font-[800] text-[1rem]">
                      Total
                    </div>
                    <div className="font-[400] text-[0.75rem] text-[#A5A5A6]">
                      Showing total of fee-payer accounts
                    </div>
                  </div>
                  <div className="text-[#FFFFFFCC] font-[800]">
                    {connected &&
                    totalFeePayerBalances !== undefined &&
                    !isNaN(totalFeePayerBalances)
                      ? `${totalFeePayerBalances} APT`
                      : `${0} APT`}
                  </div>
                </div>
                <div className="flex flex-col h-[23.5rem] overflow-y-scroll w-full">
                  <div className="px-[1rem] pt-[1rem] ">
                    {/* <div className="w-full cursor-pointer gap-[0.5rem] font-manrope font-[800] text-[0.875rem] text-[#2ED3B7] p-[1rem] flex flex-row justify-start items-center rounded-[1rem] border-2 border-[#ffffff1a] bg-[#111213]">
                      <img src={PlusCircle} alt="" />
                      <button onClick={() => addFeepayers()}>
                        Add fee-payer account
                      </button>
                    </div> */}
                  </div>
                  {details.map((detail, index) => (
                    <div className="w-full p-[1rem] flex flex-col gap-[1rem] ">
                      {detail?.feepayer_accounts.map(
                        (account: any, index: number) => (
                          <div
                            key={index}
                            className="w-full   rounded-[1rem] border-2 border-[#ffffff1a] bg-[#111213]  "
                          >
                            <div className="flex justify-between p-3">
                              <div className="flex flex-col">
                                <div className="text-[#FFFFFFCC] flex flex-row justify-start items-center gap-[1rem] text-[1rem] font-[400]">
                                  {account?.address}
                                  <img src={CopyIcon} alt="" />
                                </div>
                                <div className="flex">
                                  <div className="text-[#777879] text-[0.875rem] font-[400]">
                                    Balance
                                  </div>
                                  <div className="text-[#777879] text-[0.875rem] font-[800] pl-3">
                                    {feePayerBalance.map(
                                      (bal: any, index: any) =>
                                        account?.address === bal?.address && (
                                          <div key={index} className="ml-2">
                                            {bal?.balance !== undefined
                                              ? `${bal?.balance} APT`
                                              : `${0} APT`}
                                          </div>
                                        )
                                    )}
                                  </div>
                                  {account?.is_active ? (
                                    <div
                                      className={`text-[#12B76A] pl-3 
                                    ${account?.is_active}
                                    `}
                                    >
                                      Active
                                    </div>
                                  ) : (
                                    <div
                                      className={`text-[#FF0000] pl-3 
                                    ${account?.is_active}
                                    `}
                                    >
                                      InActive
                                    </div>
                                  )}
                                </div>
                              </div>
                              <Switch
                                onClick={() =>
                                  account?.is_active
                                    ? disableFeepayerAccount(account?.address)
                                    : enableFeepayerAccount(account?.address)
                                }
                                checked={account?.is_active}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {iswalletConnect && <ConnectWallet />}
    </div>
  );
};

export default Dashboard;
